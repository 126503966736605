<template>
    <modal-lateral ref="prodIncluidos" titulo="Productos incluidos en la tienda">
        <div class="row mx-1 p-1 d-middle border-bottom cr-pointer" style="height:110px;">
            <img class="border br-10" height="92" width="92" :src="info.foto" alt="" />
            <div class="col-8 pr-0 tres-puntos f-15">
                <div class="row mx-0 f-600">
                    <p class="tres-puntos text-general">{{ info.nombre }}</p>
                </div>
                <div class="row mx-0 align-items-center">
                    <i v-if="info.estado" class="icon-calendar-clock f-22 text-general2" />
                    <i v-else class="icon-calendar-times f-22 text-general2" />
                    <p class="tres-puntos">{{ formatearFecha(info.inicio, 'D MMM') }} - {{ formatearFecha(info.fin, 'D MMM') }}</p>
                </div>
                <div class="row mx-0 align-items-center">
                    <div class="br-10 text-white px-1 f-17 bg-general3">
                        <i class="icon-package-variant-closed f-18 mr-2" />
                        <span>{{ info.cant_productos }} productos</span>
                    </div>
                    <el-tooltip class="item" effect="light" placement="bottom">
                        <div slot="content" class="text-center" style="width:140px;">
                            Productos añadidos por el cedis
                        </div>
                        <div class="br-10 text-white px-1 f-17">
                            <i class="icon-package-variant-closed f-18 mr-2" />
                        </div>
                    </el-tooltip>
                </div>
            </div>
        </div>
        <div class="row mx-0">
            <div class="col custom-scroll overflow-auto" style="height:calc(100vh - 180px)">
                <card-producto v-for="(item, idx) in productos" :key="idx" :info="item" :diseno="detalle" :tendero="false" class="my-2 w-100" />
            </div>
        </div>
    </modal-lateral>
</template>

<script>
import {mapGetters} from 'vuex'
import Promocion from '~/services/promociones/promociones_cedis_admin'

export default {
    data(){
        return{
            info:{
                id:null,
                nombre: '',
                estado: null,
                foto: null,
                inicio: null,
                fin: null,
                cantidad: null,
                cant_productos: null,
                id_promocion: null,
                id_cedi: null,
            },
            productos:[]
        }
    },
    computed:{
        ...mapGetters({
            // productos: 'promociones/promociones/productos',
            detalle: 'promociones/promociones/detalle',
        })

    },
    methods: {
        toggle(info){
            console.log('info tiendasssssssssss', info);
            // this.id = info.id
            // this.nombre = info.nombre
            // this.foto = info.foto
            // this.inicio = info.inicio
            // this.fin= info.fin
            // this.cantidad= info.cantidad
            // this.cant_productos= info.cant_productos
            // this.id_promocion = info.id_promocion
            // this.id_cedi = info.id_cedi

            this.info = { ...info }

            this.listarProductosAgregados()

            this.$refs.prodIncluidos.toggle()
        },
        // async listarProductos(text= null){
        //     try {
        //         this.$store.dispatch('promociones/promociones/action_listar_productos', this.info.id)

        //     } catch (e){
        //         this.error_catch(e)
        //     }
        // },
        async listarProductosAgregados(){
            try {
                const enviar ={
                    id_promocion: this.info.id_promocion,
                    id_cedi: this.info.id_cedi,
                    caso: 1
                }
                const {data} = await Promocion.listarProductosAgregados(enviar)
                this.productos = data.productos

            } catch (e){
                this.error_catch(e)
            }
        },
        limpiar(){
            this.info = {
                id:null,
                nombre: '',
                estado: null,
                foto: null,
                inicio: null,
                fin: null,
                cantidad: null,
                cant_productos: null,
                id_promocion: null,
                id_cedi: null,
            }
        }
    }
}
</script>

<style lang="css" scoped>
</style>
